/**
 * @module CircularLoader
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './uiStyles.scss';

/**
 * Represents a circular loader element useful for including for loading states.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the loader (Default: '#ffffff').
 *
 * @returns {React.ReactElement} The DownloadIcon element.
 */
export default function CircularLoader({
  color = 'currentColor',
  ...passThroughProps
}) {
  return (
    <div
      className="circular-loader"
      data-color={color}
      data-testid={passThroughProps?.['data-testid'] || 'circular-loader'}
    >
      <svg
        color={color}
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="spinner-secondHalf">
            <stop offset="0%" stopOpacity="0" stopColor={color} />
            <stop offset="100%" stopOpacity="0.5" stopColor={color} />
          </linearGradient>
          <linearGradient id="spinner-firstHalf">
            <stop offset="0%" stopOpacity="1" stopColor={color} />
            <stop offset="100%" stopOpacity="0.5" stopColor={color} />
          </linearGradient>
        </defs>

        <g strokeWidth="4">
          <path
            stroke="url(#spinner-secondHalf)"
            d="M 2 12 A 8 8 0 0 1 22 12"
          />
          <path stroke="url(#spinner-firstHalf)" d="M 22 12 A 8 8 0 0 1 2 12" />

          <path
            stroke={color}
            strokeLinecap="round"
            d="M 2 12 A 8 8 0 0 1 2 12"
          />
        </g>

        <animateTransform
          attributeName="transform"
          dur="800ms"
          from="0 0 0"
          repeatCount="indefinite"
          to="360 0 0"
          type="rotate"
        />
      </svg>
    </div>
  );
}
