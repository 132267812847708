/**
 * @module RichText
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { filterAttributesForElement } from '@lifechurch/web-tools-io/dist/utils/helpers/attributes';
import { isColorSettingValidClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/classNames';
import { implementAnchorTagAttributes } from '@lifechurch/web-tools-io/dist/utils/helpers/scriptHelper';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import './RichText.scss';

const RichText = ({
  alignment = '',
  className = '',
  colorsetting,
  content,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  ...passThroughProps
}) => {
  const { isMobile } = useWindowSize();
  const [markup, setMarkup] = React.useState('');
  const colorSettingClass = convertValueToClassName(
    isColorSettingValidClassName(colorsetting) ? colorsetting : '',
  );
  const containerClass =
    `rich-text text-paragraph_large ${convertValueToClassName(
      className,
    )} ${colorSettingClass} ${convertValueToClassName(alignment)} ${
      isMobile
        ? convertValueToClassName(sbOnMobile)
        : convertValueToClassName(sbOnTabletAndUp)
    } container`.trim();
  const filteredProps = filterAttributesForElement({
    attributes: passThroughProps,
    elementType: 'div',
  });

  /**
   * Convenience effect to convert content to implement anchor tag attributes on
   * content set to be injected via dangerouslySetInnerHTML.
   */
  React.useEffect(() => {
    setMarkup(implementAnchorTagAttributes(content, 'RichText'));
  }, [content]);

  return (
    <div
      {...filteredProps}
      className={containerClass}
      dangerouslySetInnerHTML={{ __html: markup }}
      data-testid="lc-rich-text-component"
      id={sectionId}
    ></div>
  );
};

export default RichText;
