/* eslint-disable no-undef */
/**
 * @module SpecialEventContent
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { DAYS_OF_WEEK } from '@lifechurch/web-tools-io/dist/utils/helpers';
import { formatTimeTo12h } from '@lifechurch/web-tools-io/dist/utils/helpers/date';
import { implementAnchorTagAttributes } from '@lifechurch/web-tools-io/dist/utils/helpers/scriptHelper';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, ENVIRONMENT, EVENTS } from '../../helpers/constants';
import LCBackground from '../LCImage/LCBackground';
import LCImage from '../LCImage/LCImage';
import Button from '../ButtonItem/ButtonItem';
import Spans from '../IconSpans/Spans';
import EventDaysAndTimes from './EventDaysAndTimes';

/**
 * Represents the content of special events.
 *
 * @param {object} props - The component props object.
 * @param {string} props.campusCode - The location campus code.
 * @param {object} props.specialEventData - The object containing the special event data.
 * @param {object} props.specialEventTimes - The object containing special event times data.
 *
 * @returns {React.ReactElement} The SpecialEventContent component.
 */
export default function SpecialEventContent({
  campusCode,
  specialEventData,
  specialEventTimes,
}) {
  const { user } = useAuth();
  const [markup, setMarkup] = React.useState('');

  const specialNodes = specialEventTimes?.['@nodes'];
  const specialSchedule = specialNodes?.reduce((acc, node) => {
    const eventTime = specialEventTimes?.[node] || {};
    const { date, dow, time } = eventTime;

    const day = DAYS_OF_WEEK[dow];
    if (day) {
      const day_date = date ? `${day}, ${date}` : day;
      const currentDayDate = acc?.[day_date] || [];
      if (time?.toLowerCase() === 'closed') {
        acc[day_date] = ['Closed'];
      } else {
        const formattedTimes = time
          .replace('&', '')
          .split(', ')
          .map((hour) => formatTimeTo12h(hour.trim()));
        acc[day_date] = [...currentDayDate, ...formattedTimes];
      }
    }

    return acc;
  }, {});

  /**
   * Handler function for element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleButtonClick(event) {
    /* istanbul ignore next */
    if (event?.target?.preventDefault && ENVIRONMENT.test) {
      event.preventDefault();
    }
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Location Special Event',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Convenience effect to convert content to implement anchor tag attributes on
   * content set to be injected via dangerouslySetInnerHTML.
   */
  React.useEffect(() => {
    if (specialEventData?.content) {
      setMarkup(
        implementAnchorTagAttributes(
          specialEventData.content,
          'Location Special Event',
        ),
      );
    }
  }, [specialEventData]);

  if (!specialEventData && !specialEventTimes) {
    return null;
  }

  return (
    <div data-testid="lc-special-event-content">
      <div
        className={`event-image-wrapper ${
          specialEventData?.isDarkMode ? 'dark-mode' : ''
        }`.trim()}
      >
        <LCBackground
          className="event-background py-relaxed"
          src={
            specialEventData?.bgImage?.['@id'] ||
            specialEventData?.bgImage ||
            ''
          }
        >
          <LCImage
            className="event-image"
            htmlAttributes={{ alt: 'Special Event Featured Image' }}
            src={
              specialEventData?.featuredImage?.['@id'] ||
              specialEventData?.featuredImage ||
              ''
            }
            width={250}
          />
          {markup ? (
            <div dangerouslySetInnerHTML={{ __html: markup }}></div>
          ) : null}
          <Button
            buttonSize="small"
            darkVariant={specialEventData?.isDarkMode}
            onClick={handleButtonClick}
            style="btn-secondary"
            target={specialEventData.linkTarget}
            text={specialEventData.linkText}
            url={`${
              specialEventData.linkUrl
            }?location=${campusCode.toLowerCase()}`}
          />
        </LCBackground>
      </div>
      <div className="poster-event">
        <div className="poster-event-name">
          <span className="icon lc-circular-logo">
            <Spans />
          </span>
          <p>Service & LifeKids</p>
        </div>
        <div className="display-flex flex-column row-gap-normal">
          <EventDaysAndTimes schedule={specialSchedule} />
        </div>
      </div>
    </div>
  );
}
