/**
 * @module SmartLocation
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { MINIMUM_DISTANCE_KM } from '../../helpers/constants';
import ButtonItem from '../ButtonItem/ButtonItem';
import LocationFoundIcon from './LocationFoundIcon';
import NoLocationFoundIcon from './NoLocationFoundIcon';
import Loading from '../ui/CircularLoader';

/**
 * Displays location-related functionality, including a button for finding the user's
 * location and a message about the nearest campus.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isLocationLoading - Boolean flag indicating whether or not the location is in the process of loading (as determined by the parent component).
 * @param {object} props.nearestCampus - Object containing the data of the nearest location.
 * @param {Function} props.onClick - A callback function triggered when the find location button is clicked.
 * @param {boolean} props.showFindLocation - Boolean flag indicating whether or not to show the find location button.
 *
 * @returns {React.ReactElement} The SmartLocation component.
 */
function SmartLocation({
  isLocationLoading,
  nearestCampus,
  onClick,
  showFindLocation,
}) {
  /* istanbul ignore next */
  const handleButtonClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  if (nearestCampus) {
    return nearestCampus.distance.km > MINIMUM_DISTANCE_KM ? (
      <div
        className="smart-location no-location-found"
        data-testid="lc-smart-location"
      >
        <div>
          <NoLocationFoundIcon />
        </div>
        <div>
          <p>No locations found nearby.</p>
          <a href="https://live.life.church/?utm_source=smart_location&utm_medium=no_location_found&utm_campaign=smart_location">
            Check out Life.Church Online!
          </a>
        </div>
      </div>
    ) : (
      <div
        className="smart-location location-found"
        data-testid="lc-smart-location"
      >
        <LocationFoundIcon />
        <p>Closest Location: {nearestCampus.name?.trim()}</p>
      </div>
    );
  }

  if (showFindLocation) {
    return (
      <div className="smart-location" data-testid="lc-smart-location">
        {isLocationLoading ? (
          <div className="location-loading">
            <Loading />
            <p className="link-text">Finding Your Closest Location...</p>
          </div>
        ) : (
          <ButtonItem
            buttonSize="medium"
            className="find-location-near-me"
            icon={{
              externalIcon: 'find-location-icon',
              field: 'external-icons',
            }}
            onClick={handleButtonClick}
            style="btn-tertiary"
            text="Find Your Closest Location"
            url="#find-closest-location"
          />
        )}
      </div>
    );
  }

  return null;
}

export default SmartLocation;
