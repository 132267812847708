/* eslint-disable jsdoc/check-tag-names */
/* eslint-disable no-shadow */
/**
 * @module SpecialEventTimes
 */
import React from 'react';
import {
  callGtagEvent,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import _, { isEmpty, split } from 'lodash';
import Cookies from 'js-cookie';
import { calculateDistanceFromGeolocationCoordinates } from '@lifechurch/web-tools-io/dist/utils/helpers/position';
import { formatTimeTo12h } from '@lifechurch/web-tools-io/dist/utils/helpers/date';
import { ACTIONS, EVENTS, MINIMUM_DISTANCE_KM } from '../../helpers/constants';
import Spans from '../IconSpans/Spans';
import LCImage from '../LCImage/LCImage';
import LocationPosterSpan from '../LocationPoster/LocationPosterSpan';
import LocationPosterAddress from './LocationPoster/LocationPosterAddress';
import LocationPosterPhone from './LocationPoster/LocationPosterPhone';
import SmartLocation from './SmartLocation';
import {
  extractCampusLocationFromUrl,
  fetchAllLocations,
  fetchSpecialEventData,
  filterLocationsWithSpecialEvent,
  getDateString,
  groupMainEventTimes,
} from '../../helpers/eventTimesHelpers';
import './SpecialEventTimes.scss';

const SpecialEventTimes = ({
  churchOnlineDesc,
  churchOnlineLink,
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  specialEvent,
  ...passThroughProps
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [isChurchOnline, setIsChurchOnline] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [eventTimes, setEventTimes] = React.useState([]);
  const [specialEventData, setSpecialEventData] = React.useState(null);
  const [selectedLocationData, setSelectedLocationData] = React.useState('');
  const [btnUrl, setBtnUrl] = React.useState('');
  const [fakeEvent, setFakeEvent] = React.useState(null);
  const [selectedValue, setSelectedValue] = React.useState('');
  const [stateLocations, setStateLocations] = React.useState({});
  const [churchOnlineLocation, setChurchOnlineLocation] = React.useState({});
  const [userLocation, setUserLocation] = React.useState(null);
  const [showFindLocation, setShowFindLocation] = React.useState(false);
  const [permissionState, setPermissionState] = React.useState('');
  const [isLocationLoading, setIsLocationLoading] = React.useState(false);

  /**
   * Convenience function to trigger analytics events.
   *
   * @param {object} params - The function params object.
   * @param {Event} params.event - The Event object associated with the analytics call.
   * @param {object} params.properties - The properties/parameters object data to assign to the analytics event data.
   */
  /* istanbul ignore next */
  function callAnalytics({ event, properties }) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.changed,
        component: 'Special Event Times - Campus Dropdown',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: properties.segment.label,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        ...properties.segment,
      },
    });

    /* istanbul ignore next */
    callGtagEvent({
      parameters: {
        ...properties.gtag,
      },
      type: 'click',
    });
  }

  /**
   * Handler function for location link click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleLocationLinkClick(event) {
    const event_category = specialEventData
      ? specialEventData.name.trim()
      : 'Event Times';
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Special Event Times - Location Poster',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callGtagEvent({
      parameters: {
        event_category,
        event_label: `Location Poster`,
        value: event?.currentTarget?.textContent,
      },
      type: 'click',
    });
  }

  /**
   * Handler function for select element change.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  const onSelectChange = (event) => {
    const currentValue = event.target.value;

    if (currentValue === '') {
      setSelectedValue('');
      setSelectedLocationData({});
      setEventTimes([]);
      setIsChurchOnline(false);
    } else {
      const selectedLocation = locations.find(
        (item) => item['@id'] === currentValue,
      );
      /* istanbul ignore next*/
      if (selectedLocation) {
        if (specialEvent) {
          const { hasSpecialEvent } = selectedLocation;
          const special_event = hasSpecialEvent.specialEvent;

          special_event?.['@nodes']?.forEach((i) => {
            /* istanbul ignore next*/
            const isSpecialEventMatch =
              special_event[i]?.event === specialEventData?.['@id'] ||
              special_event[i]?.event?.['@id'] === specialEventData?.['@id'];

            if (isSpecialEventMatch) {
              const { eventTimes: specialEventTimes } = special_event[i];
              const arr = (specialEventTimes?.['@nodes'] ?? []).map(
                (node) => specialEventTimes[node],
              );
              setEventTimes(arr);
            }
          });
        } else {
          const { eventTimes } = selectedLocation;
          const groupedTimes = groupMainEventTimes(eventTimes);
          setEventTimes(Object.values(groupedTimes));
        }
      }

      setSelectedValue(selectedLocation['@id']);
      setSelectedLocationData(selectedLocation);

      const event_category = specialEventData
        ? specialEventData.name.trim()
        : 'Event Times';
      if (selectedLocation.name === 'Life.Church Online') {
        setIsChurchOnline(true);
        setBtnUrl(churchOnlineLink);
        callAnalytics({
          event,
          properties: {
            gtag: {
              event_category,
              event_label: 'Campus Dropdown Life.Church Online',
            },
            segment: {
              label: 'Campus Dropdown Life.Church Online',
            },
          },
        });
      } else {
        setIsChurchOnline(false);
        setBtnUrl(`/${selectedLocation.slug}`);
        const { cityOrTown, primaryAddress, state } = selectedLocation;
        const cityValue = primaryAddress?.cityOrTown || cityOrTown;
        const stateValue = primaryAddress?.state || state;

        callAnalytics({
          event,
          properties: {
            gtag: {
              event_category,
              event_label: `Campus Dropdown ${cityValue}, ${stateValue}`,
            },
            segment: {
              label: `${cityValue}, ${stateValue}`,
            },
          },
        });
      }
    }
  };

  React.useEffect(() => {
    async function loadAndSetEventLocations() {
      try {
        const allLocations = await fetchAllLocations(passThroughProps);
        let filteredLocations = [];

        if (specialEvent) {
          const specialEventDetails = await fetchSpecialEventData(
            specialEvent,
            passThroughProps,
          );
          if (specialEventDetails) {
            setSpecialEventData(specialEventDetails);
            filteredLocations = filterLocationsWithSpecialEvent(
              allLocations,
              specialEventDetails['@id'],
            );
          }
        } else {
          filteredLocations = allLocations.filter(
            (location) => location?.eventTimes?.['@nodes']?.length,
          );
        }

        const groupedLocations = _.groupBy(
          filteredLocations,
          (location) => split(location['@path'], '/')[1],
        );
        const churchOnlineFiltered = filteredLocations.find(
          (item) => item.name === 'Life.Church Online',
        );

        setChurchOnlineLocation(churchOnlineFiltered);
        setLocations(filteredLocations);
        setStateLocations(groupedLocations);

        /**
         * To support multiple query params, iterate over the urlParams.keys()
         * and compare against supported query param keys array. If match is
         * found, set `campusCode` accordingly.
         */
        const campusLocationFromUrl =
          extractCampusLocationFromUrl(filteredLocations);
        if (campusLocationFromUrl) {
          const campusId = campusLocationFromUrl['@id'];
          const nodeToAdd = { target: { value: campusId } };
          setFakeEvent(nodeToAdd);
          setSelectedValue(campusId);
        }
      } catch (error) {
        /* istanbul ignore next */
        Log.error(error);
      }
    }
    loadAndSetEventLocations(); // NOSONAR
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialEvent]);

  /**
   * Effect hook that triggers location selection based on URL parameters.
   * When both locations are loaded and a fakeEvent (from URL params) exists,
   * it automatically selects the corresponding location by calling onSelectChange.
   */
  React.useEffect(() => {
    if (locations.length > 0 && !!fakeEvent) {
      onSelectChange(fakeEvent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, fakeEvent]);

  /**
   * Single-run convenience effect to handle the permissions to ask and use
   * the user location.
   */
  React.useEffect(() => {
    const COOKIE_NAME = 'lc-user-location-consent';
    if (
      typeof window !== 'undefined' &&
      typeof navigator !== 'undefined' &&
      navigator.permissions?.query
    ) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permission) => {
          setPermissionState(permission.state);
          if (permission.state === 'prompt' && !Cookies.get(COOKIE_NAME)) {
            setShowFindLocation(true);
          } else if (permission.state === 'granted') {
            navigator.geolocation.getCurrentPosition((position) => {
              setUserLocation(position.coords);
              setShowFindLocation(false);
            });
          } else if (permission.state === 'denied') {
            setShowFindLocation(false);
          }
        });
    }
  }, []);

  /**
   * Handles the user's location request and updates the state based on the user's geolocation permission status.
   *
   * This function checks the user's geolocation permission state (`granted`, `prompt`, or `denied`).
   * - If `granted`, it retrieves the user's current position and updates the state with the coordinates.
   * - If `prompt`, it requests the user's location and sets a consent cookie if the user denies the request.
   * - If `denied`, it disables the location request UI.
   *
   * Note: This helper is designed to support scenarios where multiple SpecialEventTimes components on the same page may request the user's location.
   * It ensures each component behaves consistently based on the user's permission state and updates the shared state appropriately.
   */
  /* istanbul ignore next */
  const handleLocationRequest = () => {
    const COOKIE_NAME = 'lc-user-location-consent';
    const expireInTenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
    setIsLocationLoading(true);
    if (permissionState === 'granted') {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserLocation(position.coords);
        setShowFindLocation(false);
        setIsLocationLoading(false);
      });
    } else if (permissionState === 'prompt') {
      if (!Cookies.get(COOKIE_NAME)) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setUserLocation(position.coords);
            setShowFindLocation(false);
            setIsLocationLoading(false);
            callSegmentTrack({
              event: EVENTS.buttonAction,
              properties: {
                action: ACTIONS.clicked,
                component: 'Special Event Times - Location Permission Dialog',
                label: 'Allow',
                logged_in: !!user,
                preferred_campus: null,
                referrer: document?.referrer || null,
                title: document?.title || '',
                url: window?.location?.href,
                user_id: user?.['https://www.life.church/rock_person_alias_id'],
              },
            });
          },
          () => {
            Cookies.set(COOKIE_NAME, 'false', {
              expires: expireInTenMinutes,
            });
            setShowFindLocation(false);
            setIsLocationLoading(false);
            callSegmentTrack({
              event: EVENTS.buttonAction,
              properties: {
                action: ACTIONS.clicked,
                component: 'Special Event Times - Location Permission Dialog',
                label: 'Block',
                logged_in: !!user,
                preferred_campus: null,
                referrer: document?.referrer || null,
                title: document?.title || '',
                url: window?.location?.href,
                user_id: user?.['https://www.life.church/rock_person_alias_id'],
              },
            });
          },
        );
      }
    } else if (permissionState === 'denied') {
      setShowFindLocation(false);
      setIsLocationLoading(false);
    }

    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Special Event Times',
        label: 'Find Your Closest Location',
        logged_in: !!user,
        preferred_campus: null,
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  };

  /**
   * Convenience memoized value that stores the nearest location
   * based on the user position.
   */
  const nearestCampus = React.useMemo(() => {
    if (!userLocation) {
      return null;
    }

    let nearest = null;
    let shortestDistance = null;
    locations.forEach((location) => {
      const userCoords = {
        latitude: userLocation.latitude,
        longitude: userLocation.longitude,
      };
      const locationCoords = {
        latitude: parseFloat(location?.primaryAddress?.latitude),
        longitude: parseFloat(location?.primaryAddress?.longitude),
      };
      const distance = calculateDistanceFromGeolocationCoordinates({
        coords1: userCoords,
        coords2: locationCoords,
      });

      if (shortestDistance === null || distance.km < shortestDistance.km) {
        shortestDistance = distance;
        nearest = {
          ...location,
          distance,
        };
      }
    });

    return nearest;
  }, [userLocation, locations]);

  /**
   * Convenience effect to populate the dropdown with the nearest
   * location data.
   */
  React.useEffect(() => {
    if (nearestCampus && nearestCampus.distance.km < MINIMUM_DISTANCE_KM) {
      setSelectedValue(nearestCampus['@id']);
      setSelectedLocationData(nearestCampus);
      setBtnUrl(`https://www.life.church/${nearestCampus.slug}`);

      if (specialEvent) {
        const { hasSpecialEvent } = nearestCampus;
        const special_event = hasSpecialEvent?.specialEvent;

        /* istanbul ignore next*/
        special_event?.['@nodes']?.forEach((i) => {
          const isSpecialEventMatch =
            special_event[i]?.event === specialEventData?.['@id'] ||
            special_event[i]?.event?.['@id'] === specialEventData?.['@id'];
          if (isSpecialEventMatch) {
            const { eventTimes: specialEventTimes } = special_event[i];
            const arr = (specialEventTimes?.['@nodes'] ?? []).map(
              (node) => specialEventTimes[node],
            );
            setEventTimes(arr);
          }
        });
      } else {
        const { eventTimes } = nearestCampus;
        const groupedTimes = groupMainEventTimes(eventTimes);
        setEventTimes(Object.values(groupedTimes));
      }
    }
  }, [nearestCampus, specialEventData, specialEvent]);

  const showChurchOnline = () => {
    if (churchOnlineLocation) {
      return (
        <option value={churchOnlineLocation['@id']}>
          {churchOnlineLocation.name}
        </option>
      );
    }
    return null;
  };

  /**
   * Handler function for button item click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const onButtonClick = (event) => {
    const { cityOrTown, primaryAddress, state } = selectedLocationData;
    const cityValue = primaryAddress?.cityOrTown || cityOrTown;
    const stateValue = primaryAddress?.state || state;
    const description = isChurchOnline
      ? 'Life.Church Online'
      : `${cityValue}, ${stateValue}`;

    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Special Event Times - Button',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callGtagEvent({
      parameters: {
        event_category: specialEventData
          ? `${specialEventData.name.trim()} Times`
          : 'Event Times',
        event_label: `Campus CTA ${description}`,
      },
      type: 'click',
    });
  };

  const showOtherLocations = () => {
    delete stateLocations['Life.Church-Online']; // NOSONAR
    /* istanbul ignore next */
    const sortCitiesByName = (a, b) => {
      if (a.name.trim() < b.name.trim()) {
        return -1;
      }
      if (a.name.trim() > b.name.trim()) {
        return 1;
      }
      return 0;
    };
    return (
      !!stateLocations &&
      Object.keys(stateLocations)
        .sort() // NOSONAR
        .map((state) => {
          const sortedCities = stateLocations[state].sort(sortCitiesByName);
          return (
            <optgroup key={state} label={state.replace(/-/g, ' ')}>
              {sortedCities.map((campus) => {
                return (
                  <option key={campus['@id']} value={campus['@id']}>
                    {campus.name}
                  </option>
                );
              })}
            </optgroup>
          );
        })
    );
  };

  const buttonText = `${
    isChurchOnline
      ? 'Attend Life.Church Online'
      : `About Life.Church ${selectedLocationData.name}`
  }`;
  const containerClass = `special-event-times ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`;

  const hideDropdown = isEmpty(stateLocations) && isEmpty(churchOnlineLocation);

  return (
    <>
      <div
        className={containerClass}
        data-testid="lc-specialeventtimes"
        id={sectionId}
      >
        {!hideDropdown ? (
          <>
            <SmartLocation
              isLocationLoading={isLocationLoading}
              nearestCampus={nearestCampus}
              onClick={handleLocationRequest}
              showFindLocation={showFindLocation}
            />
            <select
              className="location-select"
              name="location-select"
              onChange={onSelectChange}
              value={selectedValue}
            >
              <option value="">Choose a Location</option>
              {showChurchOnline()}
              {showOtherLocations()}
            </select>
          </>
        ) : null}
        {(selectedLocationData && eventTimes?.length) || !!isChurchOnline ? (
          <div
            className="special-events px-normal pb-normal pt-tight"
            data-testid="lc-specialeventtimes-times"
          >
            <div className="content-wrapper special-events-wrapper">
              <div className="event-subcontainer">
                {isChurchOnline ? (
                  <p className="church-online-desc mb-tighter text-italic text-center text-paragraph_small text-gray">
                    {churchOnlineDesc}
                  </p>
                ) : null}
                {eventTimes.map((item) => (
                  <div
                    className="special-events-item pb-normal mt-normal"
                    data-testid={`day-${item.dow}`}
                    key={item['@id']}
                  >
                    <p className="date text-weight-bold mb-more_slight_tight mt-none line-height-tightest">
                      {getDateString(item)}
                    </p>
                    <div className="poster-event-times" key={item['@id']}>
                      {
                        /* istanbul ignore next */ item.time
                          ? item.time.split(',').map((eventTime, index) => (
                              <div
                                className="poster-event-time"
                                key={`${eventTime}-${index}`}
                              >
                                {formatTimeTo12h(
                                  eventTime.replace(/&/g, '').trim(),
                                )}
                              </div>
                            ))
                          : null
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* To display the address block, ensure location has multiple data elements of its primary address. */}
            {selectedLocationData.primaryAddress?.street1 &&
            selectedLocationData.primaryAddress?.cityOrTown &&
            selectedLocationData.primaryAddress?.state ? (
              <div className="content-wrapper">
                <div
                  className="location-data-item"
                  data-testid="set-location-address-phone"
                >
                  <div className="location-data display-flex flex-row mb-normal">
                    <span className="icon-marker-filled">
                      <Spans />
                    </span>
                    <LocationPosterAddress
                      address={selectedLocationData.primaryAddress}
                      onClick={handleLocationLinkClick}
                    />
                  </div>
                  {/* Note: Ignore added since test data set only includes `secondaryStreet1` instance on `secondaryAddress` data object, but both added as a fail-safe logical check. */}
                  {/* Also only checking for {secondaryStreet1|street1} on `secondaryStreet` since if that is not set, the secondary address is not needed and fully populated. */}
                  {
                    /* istanbul ignore next */ selectedLocationData
                      .secondaryAddress?.secondaryStreet1 ||
                    selectedLocationData.secondaryAddress?.street1 ? (
                      <div className="location-data display-flex flex-row mb-normal">
                        <span className="icon-about-filled">
                          <Spans />
                        </span>
                        <div>
                          <LocationPosterSpan
                            addLineBreak={true}
                            className={'text-weight-bold'}
                            text="Weekday Contact"
                          />
                          <LocationPosterAddress
                            address={{
                              ...selectedLocationData.secondaryAddress,
                              cityOrTown:
                                selectedLocationData.secondaryAddress
                                  .secondaryCityOrTown ||
                                selectedLocationData.secondaryAddress
                                  .cityOrTown,
                              latitude:
                                selectedLocationData.secondaryAddress
                                  .secondaryLatitude ||
                                selectedLocationData.secondaryAddress.latitude,
                              longitude:
                                selectedLocationData.secondaryAddress
                                  .secondaryLongitude ||
                                selectedLocationData.secondaryAddress.longitude,
                              state:
                                selectedLocationData.secondaryAddress
                                  .secondaryState ||
                                selectedLocationData.secondaryAddress.state,
                              street1:
                                selectedLocationData.secondaryAddress
                                  .secondaryStreet1 ||
                                selectedLocationData.secondaryAddress.street1,
                              street2:
                                selectedLocationData.secondaryAddress
                                  .secondaryStreet2 ||
                                selectedLocationData.secondaryAddress.street2,
                              zip:
                                selectedLocationData.secondaryAddress
                                  .secondaryZip ||
                                selectedLocationData.secondaryAddress.zip,
                            }}
                            enableAsLink={false}
                            onClick={handleLocationLinkClick}
                          />
                        </div>
                      </div>
                    ) : null
                  }
                  {selectedLocationData.phone ? (
                    <div className="location-data display-flex flex-row">
                      <span className="icon-phone-filled">
                        <Spans />
                      </span>
                      <LocationPosterPhone
                        onClick={handleLocationLinkClick}
                        phone={selectedLocationData.phone}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {selectedLocationData.pastorName ? (
              <div className="content-wrapper">
                <div
                  className="location-data-item"
                  data-testid="set-location-pastor"
                >
                  <div className="display-flex justify-left align-center column-gap-normal mb-normal">
                    {selectedLocationData.pastorImage ? (
                      <LCImage
                        className="poster-profile"
                        htmlAttributes={{ alt: 'profile' }}
                        src={`${selectedLocationData.pastorImage}`}
                        width={80}
                      />
                    ) : null}
                    <div className="poster-name display-flex flex-column justify-left align-flex-start">
                      <h2 className="text-weight-bold line-height-normal">
                        {selectedLocationData.pastorName}
                      </h2>
                      <span className="line-height-normal">Pastor</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="content-wrapper pt-normal">
              <a
                className="btn btn-secondary size-medium align-center"
                data-testid="set-button-link"
                href={implementUtmParams(btnUrl, window?.location)}
                onClick={onButtonClick}
                rel="noreferrer"
                target={isChurchOnline ? '_blank' : '_self'}
              >
                {buttonText}
              </a>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default SpecialEventTimes;
