/* eslint-disable jsdoc/require-description-complete-sentence */
/**
 * @module YoutubeVideo
 */
import React from 'react';
import YouTube from 'react-youtube';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { convertValueToClassName } from '@lifechurch/web-tools-io/dist/utils/helpers/validators';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import useWindowSize from '@lifechurch/web-tools-io/dist/hooks/useWindowSize';
import { useElapsedTime } from 'use-elapsed-time';
import { EVENTS, MEDIA_TRACKING } from '../../helpers/constants';
import './YoutubeVideo.scss';

/**
 * Represents a container to display a YouTube video, including logic to ensure
 * that YouTube is available, triggering the `onYTNotAvailable` prop handler
 * function if it is not.
 *
 * Note: istanbul ignore next is used explicity to suppress test warnings
 * about uncovered lines. Functionality confirmed as needed within the test.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.autoplay] - Boolean flag denoting whether or not to auto play the video.
 * @param {boolean} [props.hideInfo] - Boolean flag denoting whether or not to hide informational data.
 * @param {boolean} [props.loop] - Boolean flag denoting whether or not to set the video to loop and repeat playback.
 * @param {object} props.mediaData - Data object of needed media item details (e.g. `id`, `title`).
 * @param {Function} [props.onYTNotAvailable] - Handler function for YouTube player not being available.
 * @param {YouTubePlayerVars} [props.playerVarOverrides] - Optional player parameter overrides.
 * @param {string} [props.sbOnMobile] - Class for mobile screens.
 * @param {string} [props.sbOnTabletAndUp] - Class for tablet and larger screens.
 * @param {string} [props.sectionId] - The unique id value to assign to the section.
 * @param {string} props.videoOrientation - Specifies the video's orientation. Possible values: "landscape" or "portrait".
 * @param {string} [props.youtubeID] - The YouTube video id.
 *
 * @returns {React.ReactElement} The YoutubeVideo component.
 *
 * @see {@link https://developers.google.com/youtube/v3/docs/videos#status.uploadStatus}.
 * @see {@link https://developers.google.com/youtube/iframe_api_reference#Events}.
 * @see {@link https://www.npmjs.com/package/use-elapsed-time}.
 */
const YoutubeVideo = ({
  autoplay = false,
  hideInfo = false,
  loop = false,
  mediaData,
  onYTNotAvailable,
  playerVarOverrides = {},
  sbOnMobile,
  sbOnTabletAndUp,
  sectionId,
  videoOrientation,
  youtubeID,
}) => {
  const { user } = useAuth();
  const { isMobile } = useWindowSize();
  const [elapsedVideoTime, setElapsedVideoTime] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [metricsEventData, setMetricsEventData] = React.useState({});
  const [videoSnippetData, setVideoSnippetData] = React.useState(null);

  /**
   * Convenience function to call Segment track method.
   *
   * Note: For tracking, interval values are set in the `MEDIA_TRACKING`
   * constant in `segment.events.timeTriggers`. Also, in its present form,
   * the component doesn't have series or session id values, and as such,
   * only episode_id is set based on the mediaData.id value.
   *
   * @param {number|string} interval - The interval value for the Segment data properties object.
   */
  /* istanbul ignore next */
  function callAnalytics(interval) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.mediaPlayed,
      properties: {
        component: 'YouTube Video',
        episode_id: mediaData?.id,
        interval: interval === '0' ? parseInt(interval, 10) : interval,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        resource_name:
          mediaData?.title ?? document?.title?.split('|')[0].trim() ?? '',
        series_id: null,
        session_id: null,
        title: document?.title || '',
        type: 'Video',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        video_url:
          videoSnippetData?.videoUrl ||
          `https://www.youtube.com/watch?v=${youtubeID}`,
        youtube_id: youtubeID,
      },
    });
  }

  const containerClass = `video-wrapper youtube-video-wrapper
    ${videoOrientation ?? ''}
    ${hideInfo ? 'hide-info' : ''} ${
    isMobile
      ? convertValueToClassName(sbOnMobile)
      : convertValueToClassName(sbOnTabletAndUp)
  }`.trim();

  // Utilize elapsed time hook to track playback timing.
  /* istanbul ignore next */
  useElapsedTime({
    isPlaying,
    onUpdate: (value) => {
      const timeKey =
        metricsEventData?.start && !metricsEventData.start.isTracked
          ? 'start'
          : parseInt(Math.floor(value), 10);
      let metricsObject = metricsEventData[timeKey];
      setElapsedVideoTime(value);
      if (timeKey > 60 && timeKey % 60 === 0) {
        metricsObject = {
          isTracked: false,
        };
        setMetricsEventData((prevData) => {
          return {
            ...prevData,
            [timeKey]: metricsObject,
          };
        });
      }
      if (metricsObject && !metricsObject.isTracked) {
        callAnalytics(timeKey === 'start' ? '0' : timeKey);
        setMetricsEventData((prevData) => {
          return {
            ...prevData,
            [timeKey]: {
              ...prevData[timeKey],
              isTracked: true,
            },
          };
        });
      }
    },
    updateInterval: 1,
  });

  /**
   * Single-run convenience effect to set state for metrics tracking data.
   */
  React.useEffect(() => {
    Object.keys(MEDIA_TRACKING.segment.events.timeTriggers).forEach(
      (seconds) => {
        setMetricsEventData((prevData) => {
          return {
            ...prevData,
            [seconds]: {
              isTracked: false,
            },
          };
        });
      },
    );
  }, []);

  /**
   * Handler function for YouTube video state change event.
   *
   * Note: event.data value of 1 relates to "playing", and 0 to "ended".
   */
  /* istanbul ignore next */
  function handleYouTubeStateChange(event) {
    setIsPlaying(event.data === 1);
    if (event.data === 0) {
      callAnalytics(videoSnippetData?.duration ?? elapsedVideoTime);
    }
  }

  /**
   * Handler function for YouTube video ready event.
   *
   * If the `autoplay` prop is truthy, it will trigger the video to play.
   *
   * @param {Event} event - The event object associated with the ready event.
   */
  /* istanbul ignore next */
  function handleYouTubeReady(event) {
    setVideoSnippetData((prevData) => {
      return {
        ...prevData,
        duration: parseInt(event.target.getDuration(), 10),
        videoUrl: event.target.getVideoUrl(),
      };
    });

    // Get player container iframe and set data-status attribute to '1'.
    const playerContainer = document.getElementById(`ytid-${youtubeID}`);
    if (playerContainer) {
      playerContainer.dataset.status = '1';
    }
    if (autoplay) {
      event.target.playVideo();
    }
  }

  /**
   * Handler function for YouTube video error event.
   */
  /* istanbul ignore next */
  function handleOnError() {
    // Get player container iframe and set data-status attribute to '1'.
    const playerContainer = document.getElementById(`ytid-${youtubeID}`);
    if (playerContainer) {
      playerContainer.dataset.status = '0';
    }

    if (typeof onYTNotAvailable === 'function') {
      onYTNotAvailable(true);
    }
    Log.error(
      `Error playing YouTube video with id: ${youtubeID}. Player var overrides: ${JSON.stringify(
        playerVarOverrides,
      )}.`,
    );
  }

  return (
    <div
      className={containerClass}
      data-media-component="YouTube Video"
      data-media-id={mediaData?.id}
      data-media-title={mediaData?.title}
      data-media-url={
        videoSnippetData?.videoUrl ||
        `https://www.youtube.com/watch?v=${youtubeID}`
      }
      data-media-youtube-id={youtubeID}
      data-testid={`ytid-${youtubeID}`}
      id={sectionId}
    >
      <YouTube
        className="youtube-iframe"
        id={`ytid-${youtubeID}`}
        onError={handleOnError}
        onReady={handleYouTubeReady}
        onStateChange={handleYouTubeStateChange}
        opts={{
          height: '100%',
          playerVars: {
            autoplay: autoplay ? 1 : 0,
            controls: 1,
            loop: loop ? 1 : 0,
            mute: autoplay ? 1 : 0,
            playlist: loop ? youtubeID : null,
            playsinline: 1,
            rel: 0,
            showinfo: 0,
            widget_referrer: window.location.origin,
            ...playerVarOverrides,
          },
          width: '100%',
        }}
        videoId={youtubeID}
      />
    </div>
  );
};

export default YoutubeVideo;
