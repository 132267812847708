/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module MenuModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import MenuModalLink from './MenuModalLink';
import './MenuModal.scss';

const MenuModal = (props) => {
  const { user } = useAuth();

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Menu Modal',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for menu item click.
   *
   * @param {Event} event - The Event object associated with the click event.
   */
  /* istanbul ignore next */
  function handleMenuItemClick(event) {
    // If `onMenuItemSelect` prop is present and a function, invoke it and pass
    // in the function to call from it, which in this case, is the function to
    // call Segment track.
    if (
      props.onMenuItemSelect &&
      typeof props.onMenuItemSelect === 'function'
    ) {
      const anchorElements =
        event?.currentTarget?.getElementsByTagName('a') || [];
      if (anchorElements.length) {
        const spanElements =
          anchorElements[0].getElementsByClassName('modal-link-title') || [];
        if (spanElements.length) {
          props.onMenuItemSelect({
            func: handleElementClick(event), // NOSONAR
          });
        }
      }
    }
  }

  return (
    <section className="menu-modal">
      {props.rootMenu.length ? (
        <div className="modal-navigation laptop-hide">
          <ul>
            <li>
              <a
                data-menu-id="church-online"
                href={implementUtmParams(
                  'https://live.life.church/?utm_source=life_church&utm_medium=header&utm_campaign=navigation',
                  window?.location,
                )}
                onClick={handleElementClick}
              >
                <span className="modal-link-title">Attend Online</span>
                <span className="modal-link-description">
                  Check out a Life.Church message—live with our Church Online
                  community.
                </span>
              </a>
            </li>
            {props.rootMenu.map((menuItem) => (
              <li
                data-testid="lc-root-menu-item"
                key={menuItem['@id']}
                onClick={handleMenuItemClick}
              >
                <MenuModalLink menuItem={menuItem} />
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      {props.menuSections
        ? props.menuSections.map((section) => (
            <div
              className="modal-navigation"
              data-testid="lc-menu-modal-section"
              key={section['@id']}
            >
              <h5 className="modal-nav-title">{section.name}</h5>
              <ul>
                {section['@nodes']
                  ? section['@nodes'].map((itemLinkNodeName) => {
                      const itemLink = section[itemLinkNodeName];
                      return (
                        <li key={itemLink['@id']} onClick={handleMenuItemClick}>
                          <MenuModalLink menuItem={itemLink} />
                        </li>
                      );
                    })
                  : null}
              </ul>
            </div>
          ))
        : null}
    </section>
  );
};

export default MenuModal;
