/**
 * @module EventTimesHelpers
 */
import { getMagnoliaItem } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/getMagnoliaItem';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import { DAYS_OF_WEEK } from '@lifechurch/web-tools-io/dist/utils/helpers';
import { ENDPOINT_WORKSPACE_MAP, MGNL_ENV_VARS } from './constants';

/**
 * Convenience function to group event times by day of week (dow).
 * Combines events occurring on the same day by concatenating their times.
 *
 * @param {object} locationMainEventTimes - Object containing event time nodes.
 *
 * @returns {object} - Object with events grouped by day of week (dow),
 * where each key is a dow and each value is an event object with concatenated times.
 *
 * @example
 * const eventTimes = {
 *   '@nodes': ['eventTimes0', 'eventTimes1'],
 *   'eventTimes0': { dow: '5', time: '16:00', title: 'Service' },
 *   'eventTimes1': { dow: '5', time: '17:30', title: 'Service' }
 * };
 * const grouped = groupMainEventTimes(eventTimes);
 * Result: { '5': { dow: '5', time: '16:00, 17:30', title: 'Service' } }
 */
function groupMainEventTimes(locationMainEventTimes) {
  if (!locationMainEventTimes || !locationMainEventTimes['@nodes']?.length) {
    return {};
  }
  const mappedEventTimes = locationMainEventTimes['@nodes']
    .map((node) => locationMainEventTimes[node])
    .filter(Boolean);

  const groupedTimes = mappedEventTimes.reduce((acc, eventTime) => {
    const { dow, time } = eventTime;

    if (!dow || !time) {
      return acc;
    }
    if (acc[dow]) {
      acc[dow] = {
        ...acc[dow],
        time: `${acc[dow].time}, ${time}`,
      };
    } else {
      acc[dow] = eventTime;
    }
    return acc;
  }, {});
  return groupedTimes;
}

/**
 * Convenience function to filter locations that have a specific special event.
 *
 * @param {Array} locations - Array of location objects to filter.
 * @param {string} specialEventId - ID of the special event to filter by.
 *
 * @returns {Array} - Filtered array of locations that have the specified special event.
 */
function filterLocationsWithSpecialEvent(locations, specialEventId) {
  return locations.filter((location) => {
    const { hasSpecialEvent } = location;
    if (hasSpecialEvent?.field?.toString() === 'true') {
      const special_event = hasSpecialEvent?.specialEvent;
      return special_event?.['@nodes']?.find(
        (node) =>
          special_event[node].event === specialEventId ||
          special_event[node].event?.['@id'] === specialEventId,
      );
    }
    return false;
  });
}

/**
 * Convenience function to extract campus location from URL parameters and finds matching location.
 * Checks for supported URL parameters ('campus' or 'location') and uses the value
 * to find a matching location from the provided filtered locations.
 *
 * @param {Array} locations - Array of location objects to search within.
 *
 * @returns {object | null} - The matching location object or null if no match found.
 */
function extractCampusLocationFromUrl(locations) {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsKeys = [...urlParams.keys()];
  const supportedKeys = ['campus', 'location'];

  let campusCode = null;
  for (let i = 0; i < urlParamsKeys.length; i += 1) {
    const urlKey = urlParamsKeys[i];
    if (supportedKeys.includes(urlKey)) {
      campusCode = urlParams.get(urlKey);
      break;
    }
  }

  if (!campusCode) {
    return null;
  }

  const campusLocation = locations.find(
    (location) =>
      campusCode.toLowerCase() === location.campusCode.toLowerCase(),
  );

  return campusLocation || null;
}

/**
 * Convenience function to fetch special event data from the Magnolia API.
 *
 * @async
 * @param {string} specialEvent - Special event identifier (either UUID or path).
 * @param {boolean} [passThroughProps] - Flag to force API call in test environments.
 *
 * @returns {Promise<object | null>} - Promise resolving to the special event data object,
 * or null if the request fails.
 */
async function fetchSpecialEventData(specialEvent, passThroughProps) {
  /**
   * Note: The specialEvent/${specialEvent} endpoint returns 404 when the
   * value provided as `specialEvent` is a UUID value and not a string, such
   * as 'Easter' or 'Christmas'. As such, if a UUID is provided, there is a
   * need to use the `jcr:uuid` filter to return proper results.
   *
   * Also, the RegEx used is from a supported and working answer from a
   * StackOverflow question, and checks for proper UUID syntax. This is used
   * since our version of the npm `uuid` package is a deprecated, legacy
   * version that introduces many more errors when updated to a more modern
   * one. As such, using this to avoid a much larger development effort of a
   * project-wide update of all various places `uuid` is used.
   *
   * Endpoints:
   * • String-based: `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/specialEvent/${specialEvent}`.
   * • UUID-based: `${getAPIBase(MGNL_ENV_VARS)}/.rest/delivery/specialEvent?jcr:uuid=${specialEvent}`.
   *
   * References:
   * • https://docs.magnolia-cms.com/headless/api/delivery.html#_filter_types.
   * • https://stackoverflow.com/a/7905992/1914233.
   */
  try {
    const isUuid =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
        specialEvent,
      );
    const specialEventPath = isUuid
      ? `?jcr:uuid=${specialEvent}`
      : `/${specialEvent}`;
    const response = await getMagnoliaItem({
      caller:
        'src/components/SpecialEventTimes/SpecialEventTimes.js > fetchSpecialEventAndLocation',
      forceCall: passThroughProps?.testOverride,
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(`/.rest/delivery/specialEvent${specialEventPath}`),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
    const resultEventData = isUuid
      ? response?.results?.find(
          (eventData) => eventData['@id'] === specialEvent,
        )
      : response;
    return resultEventData;
  } catch (error) {
    Log.error(error);
    return null;
  }
}

/**
 * Convenience function to fetch all locations from the Magnolia API.
 *
 * @async
 * @param {boolean} [passThroughProps] - Flag to force API call in test environments.
 *
 * @returns {Promise<Array>} - Promise resolving to an array of location objects,
 * or an empty array if the request fails.
 */
async function fetchAllLocations(passThroughProps) {
  try {
    const locationsResponse = await getMagnoliaItem({
      caller:
        'src/components/SpecialEventTimes/SpecialEventTimes.js > getLocationsWithSpecialEvents',
      forceCall: passThroughProps?.testOverride,
      mgnlEnvVars: MGNL_ENV_VARS,
      path: encodeURI(`/.rest/delivery/location`),
      workspaceMap: ENDPOINT_WORKSPACE_MAP,
    }); // NOSONAR
    return locationsResponse?.results ?? [];
  } catch (error) {
    Log.error(error);
    return [];
  }
}

/**
 * Convenience function to format a date string based on the day of week (dow) and date values.
 * Takes an object with dow and date properties and returns a formatted string.
 *
 * @param {object} item - The object containing date information.
 *
 * @returns {string} - Formatted date string.
 */
const getDateString = (item) => {
  return !!DAYS_OF_WEEK[item?.dow] && item?.date
    ? `${DAYS_OF_WEEK[item.dow]}, ${item?.date}`
    : DAYS_OF_WEEK[item?.dow] || item?.date || '';
};

export {
  extractCampusLocationFromUrl,
  filterLocationsWithSpecialEvent,
  fetchSpecialEventData,
  fetchAllLocations,
  groupMainEventTimes,
  getDateString,
};
