/**
 * @module Header
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { getUserNameFromProfile } from '@lifechurch/web-tools-io/dist/utils/helpers/auth';
import { stringToSlug } from '@lifechurch/web-tools-io/dist/utils/helpers/magnolia/urls';
import { encodeSVG } from '@lifechurch/web-tools-io/dist/utils/helpers/svg';
import { implementUtmParams } from '@lifechurch/web-tools-io/dist/utils/helpers/utmParams';
import useAuth from '@lifechurch/web-tools-io/dist/hooks/useAuth';
import { ACTIONS, EVENTS } from '../../helpers/constants';
import MenuModal from '../MenuModal/MenuModal';
import logo from './logo.svg';
import userSvg from './user.svg';
import businessAvatar from '../../assets/defaultbusinessavatar.svg';
import './Header.scss';

const Header = ({ menuData }) => {
  const { checkLogin, isAuthenticated, isOrganization, logIn, logOut, user } =
    useAuth();
  const [open, setOpen] = React.useState(false);
  const [live, setLive] = React.useState(false);
  const [userAvatar, setUserAvatar] = React.useState(userSvg);
  const [userDisplayName, setUserDisplayName] = React.useState(
    isAuthenticated && user ? getUserNameFromProfile(user) : '',
  );

  /**
   * Handler function for button or link item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleElementClick(event) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: EVENTS.buttonAction,
      properties: {
        action: ACTIONS.clicked,
        component: 'Header',
        component_url: event?.currentTarget?.getAttribute('href'),
        label: event?.currentTarget?.textContent,
        logged_in: !!user,
        preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
        referrer: document?.referrer || null,
        title: document?.title || '',
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
  }

  /**
   * Handler function for usr log in click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleLogInClick(event) {
    event.preventDefault();
    if (!isAuthenticated) {
      handleElementClick(event);
      logIn();
    }
  }

  /**
   * Handler function for menu open event.
   *
   * @param {Event} event - The Event object associated with the event.
   */
  const handleOpenMenu = (event) => {
    handleElementClick(event);
    setOpen(!open);
  };

  /**
   * Handler function for user log out click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  const handleLogOut = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleElementClick(event);
    setUserAvatar(userSvg);
    logOut(window.location.origin);
  };

  /**
   * Convenience effect to check LCO live status.
   */
  React.useEffect(() => {
    let isMounted = true;
    const handleStatus = (status) => {
      // eslint-disable-next-line no-underscore-dangle
      window.__isLive = status;
      if (isMounted) {
        setLive(!!status);
      }
      return false;
    };
    const checkIfChurchIsLive = async () => {
      await fetch(`${process.env.CHOP_MIDDLEWARE_API}/v1/events/current`)
        .then((res) => res.json())
        .then((res) => {
          handleStatus(res?.response?.item?.isLive);
          return false;
        })
        .catch(() => {
          handleStatus(false);
          return false;
        });
    };

    checkIfChurchIsLive(); // NOSONAR
    return () => {
      isMounted = false;
    };
  }, []);

  /**
   * Convenience function to check user and authentication status and set the
   * avatar and display name.
   */
  const checkUserAuthStatus = React.useCallback(() => {
    if (isAuthenticated && user) {
      setUserDisplayName(getUserNameFromProfile(user));
      setUserAvatar(
        /* istanbul ignore next */ user.picture ||
          (isOrganization
            ? `data:image/svg+xml,${encodeSVG(businessAvatar)}`
            : `${process.env.IMGIX_BASE_URL}/lc-site/avatars/defaultAvatar.svg`),
      );
    } else {
      setUserDisplayName('');
      setUserAvatar(
        /* istanbul ignore next */ isOrganization
          ? `data:image/svg+xml,${encodeSVG(businessAvatar)}`
          : `${process.env.IMGIX_BASE_URL}/lc-site/avatars/defaultAvatar.svg`,
      );
    }
  }, [isAuthenticated, isOrganization, user]);

  /**
   * Single-run convenience effect to check login status.
   */
  React.useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to check user authentication status when a change is
   * detected in auth or user state.
   */
  React.useEffect(() => {
    checkUserAuthStatus();
  }, [checkUserAuthStatus, isAuthenticated, user]);

  const isContentNode = (menuItem) => menuItem['@nodeType'] === 'mgnl:content';
  const rootMenu =
    (!!menuData && menuData.filter((menuItem) => isContentNode(menuItem))) ||
    [];

  /* istanbul ignore next */
  const menuFolder =
    (!!menuData &&
      menuData.filter(
        (menuItem) =>
          menuItem['@name'] === 'menu' &&
          menuItem['@nodeType'] === 'mgnl:folder',
      )[0]) ||
    null;

  /* istanbul ignore next */
  const menuSections =
    (!!menuFolder &&
      !!menuFolder['@nodes'] &&
      menuFolder['@nodes'].map((node) => menuFolder[node])) ||
    [];

  return (
    <header className="header" data-testid="lc-header">
      <nav className="navigation container">
        <ul className={`main-nav ${open && 'active'}`}>
          <li>
            <a
              href={implementUtmParams(
                '/?utm_source=life_church&utm_medium=header&utm_campaign=navigation',
                window?.location,
              )}
            >
              <img alt="Life Church" className="logo" src={logo} />
            </a>
          </li>
          <li>
            <a
              className={`nav-desktop churchonlinelink ${!!live && 'live-now'}`}
              href="https://live.life.church/?utm_source=life_church&utm_medium=header&utm_campaign=navigation"
              onClick={handleElementClick}
              rel="noreferrer"
              target="_blank"
            >
              Attend Online{' '}
              {
                <span className={`live-status`}>
                  {live ? 'LIVE NOW' : 'STARTS SOON'}
                </span>
              }
            </a>
          </li>

          {!!menuData &&
            !!rootMenu.length &&
            rootMenu.map((rootMenuItem) => {
              return (
                <li className="nav-desktop" key={rootMenuItem['@id']}>
                  {
                    /* istanbul ignore next */ !!rootMenuItem.customLink
                      .field &&
                      rootMenuItem.customLink.field === 'internal' && (
                        <a
                          data-menu-id={stringToSlug(rootMenuItem.name)}
                          href={implementUtmParams(
                            !!rootMenuItem.customLink.internal &&
                              rootMenuItem.customLink.internal['@path'],
                            window?.location,
                          )}
                          onClick={handleElementClick}
                          rel="noreferrer"
                          target={
                            !!rootMenuItem.openInNewTab &&
                            rootMenuItem.openInNewTab === 'true'
                              ? '_blank'
                              : undefined
                          }
                        >
                          <span className="modal-link-title">
                            {rootMenuItem.name}
                          </span>
                        </a>
                      )
                  }

                  {!!rootMenuItem.customLink.field &&
                    rootMenuItem.customLink.field === 'external' && (
                      <a
                        data-menu-id={stringToSlug(rootMenuItem.name)}
                        href={implementUtmParams(
                          rootMenuItem.customLink.external,
                          window?.location,
                        )}
                        onClick={handleElementClick}
                        rel="noreferrer"
                        target={
                          /* istanbul ignore next */ !!rootMenuItem.openInNewTab &&
                          rootMenuItem.openInNewTab === 'true'
                            ? '_blank'
                            : undefined
                        }
                      >
                        <span className="modal-link-title">
                          {rootMenuItem.name}
                        </span>
                      </a>
                    )}
                </li>
              );
            })}
        </ul>
        <ul className="menu-nav">
          <li
            className={`${isAuthenticated ? 'logged-in' : ''}`}
            id="login-wrapper"
          >
            <button
              className="login"
              id="user-login"
              onClick={handleLogInClick}
            >
              <img alt="Login" className="avatar" src={userAvatar} />
              <span
                className={`login-text${
                  userDisplayName === '' && isAuthenticated
                    ? ' no-username'
                    : ''
                }`}
              >
                {isAuthenticated ? userDisplayName : 'LOG IN'}
              </span>
            </button>
            {isAuthenticated ? (
              <ul className={`profile-menu`}>
                <li className="menu-item">
                  <a
                    className="menu-item-link"
                    href={implementUtmParams('/profile/', window?.location)}
                    onClick={handleElementClick}
                  >
                    My Profile
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    className="menu-item-link"
                    href={implementUtmParams('/contact/', window?.location)}
                    onClick={handleElementClick}
                  >
                    Help
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    className="menu-item-link logout"
                    href="/logout/"
                    id="user-logout"
                    onClick={handleLogOut}
                  >
                    Log Out
                  </a>
                </li>
              </ul>
            ) : null}
          </li>
          <li>
            <button
              className="hamburger-menu"
              data-menu-id="hamburger-menu"
              onClick={handleOpenMenu}
            >
              <div className={`hamburger ${open && 'active'}`}>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <span>MENU</span>
            </button>
          </li>
        </ul>
      </nav>
      {open ? (
        <MenuModal
          menuSections={menuSections}
          onMenuItemSelect={({ func }) => {
            /* istanbul ignore next */
            if (func && typeof func === 'function') {
              func();
            }
            /* istanbul ignore next */
            setOpen(false);
          }}
          rootMenu={rootMenu}
        />
      ) : null}
    </header>
  );
};

export default Header;
