/**
 * @module MagnoliaConfig
 */
import Basic from './pages/Basic';
import Careers from './pages/Careers';

import Accordion from './components/Accordion/Accordion';
import AccordionItem from './components/AccordionItem/AccordionItem';
import AncherNavigation from './components/AncherNavigation/AncherNavigation';
import Banner from './components/Banner/Banner';
import CopyThisText from './components/CopyThisText/CopyThisText';
import CountdownTimer from './components/CountdownTimer/CountdownTimer';
import EmbedItem from './components/EmbedItem/EmbedItem';
import Heading from './components/Heading/Heading';
import HomePageHero from './components/HomePageHero/HomePageHero';
import LocationDetail from './components/LocationDetail/LocationDetail';
import LocationsListing from './components/LocationsListing/LocationsListing';
import MediaAsset from './components/MediaAsset/MediaAsset';
import MediaCollection from './components/MediaCollection/MediaCollection';
import MediaCollectionListing from './components/MediaCollectionListing/MediaCollectionListing';
import MediaDetail from './components/MediaDetail/MediaDetail';
import MediaThumbnail from './components/MediaThumbnail/MediaThumbnail';
import OpenContentList from './components/OpenContentList/OpenContentList';
import OpenContentListArticle from './components/OpenContentListArticle/OpenContentListArticle';
import OpenContentListItem from './components/OpenContentListItem/OpenContentListItem';
import CompositeButtonGroup from './components/CompositeButtonGroup/CompositeButtonGroup';
import ButtonItem from './components/ButtonItem/ButtonItem';
import DualContentZone from './components/DualContentZone/DualContentZone';
import Carousel from './components/Carousel/Carousel';
import ContentList from './components/ContentList/ContentList';
import FindsContentList from './components/ContentList/FindsContentList';
import RichText from './components/RichText/RichText';
import SingleContentZone from './components/SingleContentZone/SingleContentZone';
import TalkItOver from './components/TalkItOver/TalkItOver';
import Spacer from './components/Spacer/Spacer';
import Search from './components/Search/Search';
import Video from './components/Video/Video';

import FormHeading from './components/Form/FormHeading/FormHeading';
import Input from './components/Form/Input/Input';
import Select from './components/Form/Select/Select';
import SelectOptions from './components/Form/Select/SelectOptions';
import SpecialEventTimes from './components/SpecialEventTimes/SpecialEventTimes';
import LocationInfoCard from './components/SpecialEventTimes/LocationInfoCard';

const config = {
  componentMappings: {
    // Components
    'lifechurch:components/accordion': Accordion,
    'lifechurch:components/accordionitem': AccordionItem,
    'lifechurch:components/anchernavigation': AncherNavigation,
    'lifechurch:components/banner': Banner,
    'lifechurch:components/buttonitem': ButtonItem,
    'lifechurch:components/carousel': Carousel,
    'lifechurch:components/compositebuttongroup': CompositeButtonGroup,
    'lifechurch:components/contentlist': ContentList,
    'lifechurch:components/copythistext': CopyThisText,
    'lifechurch:components/countdowntimer': CountdownTimer,
    'lifechurch:components/dualcontentzone': DualContentZone,
    'lifechurch:components/embeddableform': EmbedItem,
    'lifechurch:components/embeditem': EmbedItem,
    'lifechurch:components/findscontentlist': FindsContentList,
    'lifechurch:components/formheading': FormHeading,
    'lifechurch:components/heading': Heading,
    'lifechurch:components/homepagehero': HomePageHero,
    'lifechurch:components/input': Input,
    'lifechurch:components/locationdetail': LocationDetail,
    'lifechurch:components/locationinfocard': LocationInfoCard,
    'lifechurch:components/locationslisting': LocationsListing,
    'lifechurch:components/mediaasset': MediaAsset,
    'lifechurch:components/mediacollection': MediaCollection,
    'lifechurch:components/mediacollectionlisting': MediaCollectionListing,
    'lifechurch:components/mediacollectionV2': MediaCollection,
    'lifechurch:components/mediadetail': MediaDetail,
    'lifechurch:components/mediadetailV2': MediaDetail,
    'lifechurch:components/mediathumbnail': MediaThumbnail,
    'lifechurch:components/opencontentlist': OpenContentList,
    'lifechurch:components/opencontentlistarticle': OpenContentListArticle,
    'lifechurch:components/opencontentlistcards': OpenContentList,
    'lifechurch:components/opencontentlistcarousel': OpenContentList,
    'lifechurch:components/opencontentlistitem': OpenContentListItem,
    'lifechurch:components/richtext': RichText,
    'lifechurch:components/search': Search,
    'lifechurch:components/select': Select,
    'lifechurch:components/selectoption': SelectOptions,
    'lifechurch:components/singlecontentzone': SingleContentZone,
    'lifechurch:components/spacer': Spacer,
    'lifechurch:components/specialeventtimes': SpecialEventTimes,
    'lifechurch:components/talkitoverdetail': TalkItOver,
    'lifechurch:components/video': Video,

    // Templates
    'lifechurch:pages/lifechurchbase': Basic,
    'lifechurch:pages/lifechurchcareers': Careers,
    'lifechurch:pages/lifechurchsearch': Basic,
  },
};

export default config;
